import React from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { addContactUs } from "../api/sendDataApi";

const Contact = () => {
  const form = useFormik({
    initialValues: {
      name: "",
      email: "",
      contactNo: "",
      subject: "",
      message: "",
    },
    onSubmit: async (values) => {
      const data = {
        name: values.name,
        email: values.email,
        mobile_no: values.contactNo,
        subject: values.subject,
        messages: values.message,
      };
      await addContactUs(data)
        .then((resp) => {
          if (resp?.status === "Success") {
            toast.success(resp.message);
          } else {
            toast.error(resp.message);
          }
        })
        .catch((err) => toast.error(err.message))
        .finally(form.resetForm);
    },
    validate: (values) => {
      const errors = {};
      const contactPattern = /^\d{10}$/;

      if (!values.name) {
        errors.name = "Name is required";
      }
      if (!values.contactNo) {
        errors.contactNo = "Contact number is required";
      } else if (!contactPattern.test(values.contactNo)) {
        errors.contactNo = "Contact number must be exactly 10 digits";
      }
      if (!values.subject) {
        errors.subject = "Subject is required";
      }
      return errors;
    },
  });

  return (
    <div>
      <div id="loading-area" />
      <div className="page-wraper">
        <div className="page-content">
          <div
            className="dez-bnr-inr overlay-black-middle"
            style={{ backgroundImage: "url(images/background/bg4.jpg)" }}
          >
            <div className="container px-5">
              <div className="dez-bnr-inr-entry text-start">
                <h1 className="text-white">Contact us</h1>
              </div>
            </div>
          </div>
          <div className="breadcrumb-row">
            <div className="container px-5">
              <ul className="list-inline text-start">
                <li>
                  <Link to="/" className="text-dark">
                    Home
                  </Link>
                </li>
                <li> Contact us</li>
              </ul>
            </div>
          </div>
          <div className="section-full content-inner bg-white contact-style-1">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 page-content ">
                  <div className="bg-white">
                    <section className="section-full content-inner-1 p-0">
                      <div className="container text-start">
                        <div className="row">
                          <div className="col-lg-12 col-md-12 contact-style-1">
                            <div className="p-a30 bg-gray rounded clearfix">
                              <h2>Send Message Us</h2>
                              <form onSubmit={form.handleSubmit}>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Name</label>
                                      <div className="input-group">
                                        <input
                                          name="name"
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Your Name"
                                          value={form.values.name}
                                          onChange={form.handleChange}
                                        />
                                      </div>
                                      {form.errors.name && (
                                        <span className="error text-danger">
                                          {form.errors.name}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Email Address</label>
                                      <div className="input-group">
                                        <input
                                          name="email"
                                          type="email"
                                          className="form-control"
                                          placeholder="Enter Your Email Id"
                                          value={form.values.email}
                                          onChange={form.handleChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Contact Number</label>
                                      <div className="input-group">
                                        <input
                                          name="contactNo"
                                          type="number"
                                          className="form-control"
                                          placeholder="Enter Your Contact No"
                                          value={form.values.contactNo}
                                          onChange={form.handleChange}
                                        />
                                      </div>
                                      {form.errors.contactNo && (
                                        <span className="error text-danger">
                                          {form.errors.contactNo}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Subject</label>
                                      <div className="input-group">
                                        <input
                                          name="subject"
                                          type="text"
                                          className="form-control"
                                          placeholder="Subject"
                                          value={form.values.subject}
                                          onChange={form.handleChange}
                                        />
                                      </div>
                                      {form.errors.subject && (
                                        <span className="error text-danger">
                                          {form.errors.subject}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <label>Message</label>
                                      <div className="input-group">
                                        <input
                                          name="message"
                                          type="textarea"
                                          className="form-control"
                                          placeholder="Your Message..."
                                          value={form.values.message}
                                          onChange={form.handleChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                    <div className="col-lg-12">
                                      <button
                                        name="submit"
                                        type="submit"
                                        value="Submit"
                                        className="site-button"
                                      >
                                        {" "}
                                        <span>Submit</span>{" "}
                                      </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div className="col-lg-4 d-lg-flex">
                  <div className="p-a30 m-b30 border-1 contact-area align-self-stretch">
                    <h2 className="m-b10 text-start mx-4">Quick Contact</h2>
                    <p className="text-start mx-4">
                      If you have any questions simply use the following contact
                      details.
                    </p>
                    <ul className="no-margin text-start">
                      <li className="icon-bx-wraper left m-b30">
                        <div
                          className="icon-bx-xs"
                          style={{ background: "#9a2331" }}
                        >
                          {" "}
                          <a href="javascript:void(0);" className="icon-cell">
                            <i className="fas fa-map-marker-alt text-white" />
                          </a>{" "}
                        </div>
                        <div className="icon-content">
                          <h6 className="text-uppercase m-tb0 dez-tilte">
                            Address:
                          </h6>
                          <p>
                            {" "}
                            FF 22 National Chamber, Nr City Gold, Ashram Rd,
                            Ahmedabad, Gujarat 380058{" "}
                          </p>
                        </div>
                      </li>
                      <li className="icon-bx-wraper left  m-b30">
                        <div
                          className="icon-bx-xs"
                          style={{ background: "#9a2331" }}
                        >
                          {" "}
                          <a href="javascript:void(0);" className="icon-cell">
                            <i className="fa fa-envelope text-white" />
                          </a>{" "}
                        </div>
                        <div className="icon-content">
                          <h6 className="text-uppercase m-tb0 dez-tilte">
                            Email:
                          </h6>
                          <p>info@aranathgroup.com</p>
                        </div>
                      </li>
                      <li className="icon-bx-wraper left">
                        <div
                          className="icon-bx-xs"
                          style={{ background: "#9a2331" }}
                        >
                          {" "}
                          <a href="javascript:void(0);" className="icon-cell">
                            <i className="fa fa-phone text-white" />
                          </a>{" "}
                        </div>
                        <div className="icon-content">
                          <h6 className="text-uppercase m-tb0 dez-tilte">
                            PHONE
                          </h6>
                          <p>+91-8141487740</p>
                        </div>
                      </li>
                    </ul>
                    <div className="m-t20">
                      <ul className="dez-social-icon dez-social-icon-lg mx-4">
                        <li className="px-1">
                          <a
                            href="https://www.facebook.com/aranathgroup?mibextid=LQQJ4d"
                            className="fab fa-facebook-f"
                            style={{ background: "#9a2331" }}
                            target="_blank"
                          />
                        </li>
                        <li className="px-1">
                          <a
                            href="https://www.linkedin.com/company/aranath-group/"
                            className="fab fa-linkedin-in"
                            style={{ background: "#9a2331" }}
                            target="_blank"
                          />
                        </li>
                        <li className="px-1">
                          <a
                            href="https://www.instagram.com/aranathgroup/"
                            className="fab fa-instagram"
                            style={{ background: "#9a2331" }}
                            target="_blank"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mb-5">
                  <h2 className="text-start">Our Location</h2>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14687.154416890491!2d72.5697644!3d23.0315328!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e85808883649b%3A0xb64946f72ee3c417!2sAranath%20Group!5e0!3m2!1sen!2sin!4v1710495939721!5m2!1sen!2sin"
                    width="100%"
                    height={450}
                    allowFullScreen
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
