import axios from "axios";

const authServices = axios.create({
  baseURL: `https://api.aranathgroup.com`,
  headers: {
    Accept: "application/json",
  },
});

export const authInstanceMultipart = axios.create({
  baseURL: `https://api.aranathgroup.com`,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const sendData = async (data) => {
  const response = await authServices.post(
    `/api/inquiry/addBusinessPartner`,
    data
  );
  return response?.data;
};

export const addContactUs = async (data) => {
  const response = await authServices.post(`/api/inquiry/addContactUs`, data);
  return response?.data;
};
export const fileUpload = async (data) => {
  const response = await authInstanceMultipart.post(`/api/fileUploads`, data);
  return response?.data;
};
