import React, { useState } from "react";
import { Link } from "react-router-dom";

const Projects = () => {
  const [active, setActive] = useState(true);
  const projects = [
    "Sattva Exotica",
    "Shubh Greens",
    "Tata Projects – Anant University",
    "Sanskardham",
    "Ozone Tuscany",
    "Iscon LIG Housing",
    "SPG Evans",
    "Sepal Elegant",
    "Hirabaug",
    "Private Bungalow at Khoraj",
  ];
  const project = [
    {
      title: "Sattva Exotica",
      image: "pic4.jpg",
    },
    {
      title: "Shubh Greens",
      image: "shubh.jpeg",
    },
    {
      title: "Tata Projects – Anant University",
      image: "pic3.jpg",
    },
    {
      title: "Sanskardham",
      image: "pic4.jpg",
    },
    {
      title: "Ozone Tuscany",
      image: "pic5.jpg",
    },
    {
      title: "Iscon LIG Housing",
      image: "pic6.jpg",
    },
    {
      title: "SPG Evans",
      image: "pic8.jpg",
    },
    {
      title: "Sepal Elegant",
      image: "pic9.jpg",
    },
    {
      title: "Sattva Exotica",
      image: "pic2.jpg",
    },
    {
      title: "Hirabaug",
      image: "pic3.jpg",
    },
    {
      title: "Private Bungalow at Khoraj",
      image: "pic4.jpg",
    },
  ];

  const toggle = () => {
    setActive(!active);
  };
  return (

    <div className="page-wrapper">
      <div
            className="dez-bnr-inr overlay-black-middle"
            style={{ backgroundImage: "url(images/background/bg4.jpg)" }}
          >
            <div className="container px-5">
              <div className="dez-bnr-inr-entry text-start">
                <h1 className="text-white">Projects</h1>
              </div>
            </div>
          </div>
          <div className="breadcrumb-row">
            <div className="container px-5">
              <ul className="list-inline text-start">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Projects</li>
              </ul>
            </div>
          </div>
    <div className="d-flex justify-content-center align-items-center bg-light h-100 w-100">
      <div className="container-fluid my-5">
        <div className="d-flex justify-content-center">
          <ul className="">
            <li
              onClick={toggle}
              className={`projectbtn btn border-0 px-0 ${active === true ? "active" : ""}`}
            >
              <a className="site-button-secondry button-skew">
                <span className="">Ongoing</span>
              </a>
            </li>
            <li
              onClick={toggle}
              className={`projectbtn btn border-0 px-0 ${active === false ? "active" : ""}`}
            >
              <a className="site-button-secondry button-skew">
                <span className="">Completed</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="clearfix clearfixProjects" style={{ height: "100% !important" }}>
          <ul className="h-100 row d-flex justify-content-start align-items-center">
            {
              active === true ? (
                <li className="completed projectimg col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="dez-box debox">
                  <div className="dez-thum dez-img-overlay1 dez-img-effect zoom-slow">
                      <img
                        src={`images/gallery/pic1.jpg`}
                        alt=""
                      />
                    <div className="overlay-bx">
                      <div className="overlay-icon">
                        <h2 className="m-t0 text-white">
                          Tatva 3
                        </h2>
                        <div className="clearfix m-b15">
                          <div className="dez-separator bg-white"></div>
                        </div>
                        <a
                          href={`images/gallery/pic1.jpg`}
                          className=""
                          target="_blank"
                          title="View image"
                        >
                          <i className="projecticon fa fa-image d-flex justify-content-center align-items-center icon-bx-xs radius-sm"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              ) : (
                project?.map((value, index) => {
                  return (
                    <li className="completed projectimg col-lg-3 col-md-3 col-sm-12 col-12" key={index}>
                      <div className="dez-box debox">
                        <div className="dez-thum dez-img-overlay1 dez-img-effect zoom-slow">
                            <img
                              src={`images/gallery/${value?.image}`}
                              alt=""
                            />
                          <div className="overlay-bx">
                            <div className="overlay-icon">
                              <h2 className="m-t0 text-white">
                                {value?.title}
                              </h2>
                              <div className="clearfix m-b15">
                                <div className="dez-separator bg-white"></div>
                              </div>
                              <a
                                href={`images/gallery/${value?.image}`}
                                className=""
                                target="_blank"
                                title="View image"
                              >
                                <i className="projecticon fa fa-image d-flex justify-content-center align-items-center icon-bx-xs radius-sm"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })
              )
              //   <li className="completed card-container col-lg-3 col-md-3 col-sm-6 col-6">
              //   <div className="dez-box dez-gallery-box">
              //     <div className="dez-thum dez-img-overlay1 dez-img-effect zoom-slow">
              //       {" "}
              //       <a href="javascript:void(0);">
              //         {" "}
              //         <img src="images/gallery/pic3.jpg" alt="" />{" "}
              //       </a>
              //       <div className="overlay-bx">
              //         <div className="overlay-icon">
              //           <h2 className="m-t0 text-white">Sattva Exotica</h2>
              //           <div className="clearfix m-b15">
              //             <div className="dez-separator bg-white"></div>
              //           </div>
              //           <a href="javascript:void(0);">
              //             <i className="fa fa-link icon-bx-xs radius-sm"></i>
              //           </a>
              //           <a
              //             href="images/gallery/pic3.jpg"
              //             className="mfp-link"
              //             title="Tile Come Here"
              //           >
              //             <i className="far fa-image icon-bx-xs radius-sm"></i>
              //           </a>
              //         </div>
              //       </div>
              //     </div>
              //   </div>
              // </li>
            }
            {/* <li className="completed card-container col-lg-3 col-md-3 col-sm-6 col-6">
              <div className="dez-box dez-gallery-box">
                <div className="dez-thum dez-img-overlay1 dez-img-effect zoom-slow">
                  {" "}
                  <a href="javascript:void(0);">
                    {" "}
                    <img src="images/gallery/pic3.jpg" alt="" />{" "}
                  </a>
                  <div className="overlay-bx">
                    <div className="overlay-icon">
                      <h2 className="m-t0 text-white">Sattva Exotica</h2>
                      <div className="clearfix m-b15">
                        <div className="dez-separator bg-white"></div>
                      </div>
                      <a href="javascript:void(0);">
                        <i className="fa fa-link icon-bx-xs radius-sm"></i>
                      </a>
                      <a
                        href="images/gallery/pic3.jpg"
                        className="mfp-link"
                        title="Tile Come Here"
                      >
                        <i className="far fa-image icon-bx-xs radius-sm"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="completed card-container col-lg-3 col-md-3 col-sm-6 col-6">
              <div className="dez-box dez-gallery-box">
                <div className="dez-thum dez-img-overlay1 dez-img-effect zoom-slow">
                  {" "}
                  <a href="javascript:void(0);">
                    {" "}
                    <img src="images/gallery/pic4.jpg" alt="" />{" "}
                  </a>
                  <div className="overlay-bx">
                    <div className="overlay-icon">
                      <h2 className="m-t0 text-white">Shubh Greens</h2>
                      <div className="clearfix m-b15">
                        <div className="dez-separator bg-white"></div>
                      </div>
                      <a href="javascript:void(0);">
                        <i className="fa fa-link icon-bx-xs radius-sm"></i>
                      </a>
                      <a
                        href="images/gallery/pic4.jpg"
                        className="mfp-link"
                        title="Tile Come Here"
                      >
                        <i className="far fa-image icon-bx-xs radius-sm"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="completed card-container col-lg-3 col-md-3 col-sm-6 col-6">
              <div className="dez-box dez-gallery-box">
                <div className="dez-thum dez-img-overlay1 dez-img-effect zoom-slow">
                  {" "}
                  <a href="javascript:void(0);">
                    {" "}
                    <img src="images/gallery/pic5.jpg" alt="" />{" "}
                  </a>
                  <div className="overlay-bx">
                    <div className="overlay-icon">
                      <h2 className="m-t0 text-white">Tata Projects – Anant University</h2>
                      <div className="clearfix m-b15">
                        <div className="dez-separator bg-white"></div>
                      </div>
                      <a href="javascript:void(0);">
                        <i className="fa fa-link icon-bx-xs radius-sm"></i>
                      </a>
                      <a
                        href="images/gallery/pic5.jpg"
                        className="mfp-link"
                        title="Tile Come Here"
                      >
                        <i className="far fa-image icon-bx-xs radius-sm"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="completed card-container col-lg-3 col-md-3 col-sm-6 col-6">
              <div className="dez-box dez-gallery-box">
                <div className="dez-thum dez-img-overlay1 dez-img-effect zoom-slow">
                  {" "}
                  <a href="javascript:void(0);">
                    {" "}
                    <img src="images/gallery/pic6.jpg" alt="" />{" "}
                  </a>
                  <div className="overlay-bx">
                    <div className="overlay-icon">
                      <h2 className="m-t0 text-white">Sanskardham</h2>
                      <div className="clearfix m-b15">
                        <div className="dez-separator bg-white"></div>
                      </div>
                      <a href="javascript:void(0);">
                        <i className="fa fa-link icon-bx-xs radius-sm"></i>
                      </a>
                      <a
                        href="images/gallery/pic6.jpg"
                        className="mfp-link"
                        title="Tile Come Here"
                      >
                        <i className="far fa-image icon-bx-xs radius-sm"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="completed card-container col-lg-3 col-md-3 col-sm-6 col-6">
              <div className="dez-box dez-gallery-box">
                <div className="dez-thum dez-img-overlay1 dez-img-effect zoom-slow">
                  {" "}
                  <a href="javascript:void(0);">
                    {" "}
                    <img src="images/gallery/pic8.jpg" alt="" />{" "}
                  </a>
                  <div className="overlay-bx">
                    <div className="overlay-icon">
                      <h2 className="m-t0 text-white">Ozone Tuscany</h2>
                      <div className="clearfix m-b15">
                        <div className="dez-separator bg-white"></div>
                      </div>
                      <a href="javascript:void(0);">
                        <i className="fa fa-link icon-bx-xs radius-sm"></i>
                      </a>
                      <a
                        href="images/gallery/pic1.jpg"
                        className="mfp-link"
                        title="Tile Come Here"
                      >
                        <i className="far fa-image icon-bx-xs radius-sm"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="completed card-container col-lg-3 col-md-3 col-sm-6 col-6">
              <div className="dez-box dez-gallery-box">
                <div className="dez-thum dez-img-overlay1 dez-img-effect zoom-slow">
                  {" "}
                  <a href="javascript:void(0);">
                    {" "}
                    <img src="images/gallery/pic2.jpg" alt="" />{" "}
                  </a>
                  <div className="overlay-bx">
                    <div className="overlay-icon">
                      <h2 className="m-t0 text-white">Iscon LIG Housing</h2>
                      <div className="clearfix m-b15">
                        <div className="dez-separator bg-white"></div>
                      </div>
                      <a href="javascript:void(0);">
                        <i className="fa fa-link icon-bx-xs radius-sm"></i>
                      </a>
                      <a
                        href="images/gallery/pic2.jpg"
                        className="mfp-link"
                        title="Tile Come Here"
                      >
                        <i className="far fa-image icon-bx-xs radius-sm"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="completed card-container col-lg-3 col-md-3 col-sm-6 col-6">
              <div className="dez-box dez-gallery-box">
                <div className="dez-thum dez-img-overlay1 dez-img-effect zoom-slow">
                  {" "}
                  <a href="javascript:void(0);">
                    {" "}
                    <img src="images/gallery/pic9.jpg" alt="" />{" "}
                  </a>
                  <div className="overlay-bx">
                    <div className="overlay-icon">
                      <h2 className="m-t0 text-white">SPG Evans</h2>
                      <div className="clearfix m-b15">
                        <div className="dez-separator bg-white"></div>
                      </div>
                      <a href="javascript:void(0);">
                        <i className="fa fa-link icon-bx-xs radius-sm"></i>
                      </a>
                      <a
                        href="images/gallery/pic9.jpg"
                        className="mfp-link"
                        title="Tile Come Here"
                      >
                        <i className="far fa-image icon-bx-xs radius-sm"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="completed card-container col-lg-3 col-md-3 col-sm-6 col-6">
              <div className="dez-box dez-gallery-box">
                <div className="dez-thum dez-img-overlay1 dez-img-effect zoom-slow">
                  {" "}
                  <a href="javascript:void(0);">
                    {" "}
                    <img src="images/gallery/pic8.jpg" alt="" />{" "}
                  </a>
                  <div className="overlay-bx">
                    <div className="overlay-icon">
                      <h2 className="m-t0 text-white">Sepal Elegant</h2>
                      <div className="clearfix m-b15">
                        <div className="dez-separator bg-white"></div>
                      </div>
                      <a href="javascript:void(0);">
                        <i className="fa fa-link icon-bx-xs radius-sm"></i>
                      </a>
                      <a
                        href="images/gallery/pic8.jpg"
                        className="mfp-link"
                        title="Tile Come Here"
                      >
                        <i className="far fa-image icon-bx-xs radius-sm"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="completed card-container col-lg-3 col-md-3 col-sm-6 col-6">
              <div className="dez-box dez-gallery-box">
                <div className="dez-thum dez-img-overlay1 dez-img-effect zoom-slow">
                  {" "}
                  <a href="javascript:void(0);">
                    {" "}
                    <img src="images/gallery/pic2.jpg" alt="" />{" "}
                  </a>
                  <div className="overlay-bx">
                    <div className="overlay-icon">
                      <h2 className="m-t0 text-white">Hirabaug</h2>
                      <div className="clearfix m-b15">
                        <div className="dez-separator bg-white"></div>
                      </div>
                      <a href="javascript:void(0);">
                        <i className="fa fa-link icon-bx-xs radius-sm"></i>
                      </a>
                      <a
                        href="images/gallery/pic2.jpg"
                        className="mfp-link"
                        title="Tile Come Here"
                      >
                        <i className="far fa-image icon-bx-xs radius-sm"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="completed card-container col-lg-3 col-md-3 col-sm-6 col-6">
              <div className="dez-box dez-gallery-box">
                <div className="dez-thum dez-img-overlay1 dez-img-effect zoom-slow">
                  {" "}
                  <a href="javascript:void(0);">
                    {" "}
                    <img src="images/gallery/pic2.jpg" alt="" />{" "}
                  </a>
                  <div className="overlay-bx">
                    <div className="overlay-icon">
                      <h2 className="m-t0 text-white">Private Bungalow at Khoraj</h2>
                      <div className="clearfix m-b15">
                        <div className="dez-separator bg-white"></div>
                      </div>
                      <a href="javascript:void(0);">
                        <i className="fa fa-link icon-bx-xs radius-sm"></i>
                      </a>
                      <a
                        href="images/gallery/pic2.jpg"
                        className="mfp-link"
                        title="Tile Come Here"
                      >
                        <i className="far fa-image icon-bx-xs radius-sm"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Projects;
