import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="page-wraper">
      <div className="page-content font-style-2">
        <div className="main-slider style-two default-banner" id="home">
          <div className="tp-banner-container">
            <div className="tp-banner">
              <div
                id="rev_slider_1175_1_wrapper"
                className="rev_slider_wrapper fullscreen-container"
                data-alias="duotone192"
                data-source="gallery"
                style={{ backgroundColor: "transparent", padding: "0px" }}
              >
                <div
                  id="rev_slider_1175_1"
                  className="rev_slider fullscreenbanner"
                  style={{ display: "none" }}
                  data-version="5.3.0.2"
                >
                  <ul>
                    <li
                      data-index="rs-3239"
                      data-transition="fade"
                      data-slotamount="default"
                      data-hideafterloop={0}
                      data-hideslideonmobile="off"
                      data-easein="default"
                      data-easeout="default"
                      data-masterspeed="default"
                      data-thumb="images/main-slider/slide14.jpg"
                      data-rotate={0}
                      data-fstransition="fade"
                      data-fsmasterspeed={300}
                      data-fsslotamount={7}
                      data-saveperformance="off"
                      data-title="Slide"
                      data-param1
                      data-param2
                      data-param3
                      data-param4
                      data-param5
                      data-param6
                      data-param7
                      data-param8
                      data-param9
                      data-param10
                      data-description
                    >
                      <video
                        src="images/bg1.mp4"
                        id="vid"
                        className="video"
                        poster="./images/background/bg4.jpg"
                        autoPlay
                        loop
                        muted
                      />
                      <div
                        className="tp-caption tp-shape tp-shapewrapper "
                        id="slide-100-layer-1"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['0','0','0','0']"
                        data-width="full"
                        data-height="full"
                        data-whitespace="nowrap"
                        data-type="shape"
                        data-basealign="slide"
                        data-responsive_offset="off"
                        data-responsive="off"
                        data-frames='[{"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},{"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 2,
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          borderColor: "rgba(0, 0, 0, 0)",
                          borderWidth: "0px",
                        }}
                      ></div>
                      <div
                        className="tp-caption   rs-parallaxlevel-4"
                        id="slide-3239-layer-1"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['-70','-70','-70','-100']"
                        data-fontsize="['60','60','40','20']"
                        data-lineheight="['70','70','50','30']"
                        data-width="['720','640','480','300']"
                        data-height="none"
                        data-whitespace="normal"
                        data-type="text"
                        data-responsive_offset="off"
                        data-responsive="off"
                        data-frames='[{"from":"y:20px;sX:0.9;sY:0.9;opacity:0;","speed":1000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                        data-textalign="['center','center','center','center']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 5,
                          minWidth: "720px",
                          maxWidth: "720px",
                          rdxthemeSpace: "normal",
                          fontSize: "60px",
                          lineHeight: "20px",
                          color: "#fff",
                          borderWidth: "0px",
                          letterSpacing: "10px",
                          fontWeight: 900,
                        }}
                      >
                        WE
                        <br />
                        <span style={{ color: "#9a2331" }}>DEVELOP</span>
                      </div>
                      <div
                        className="tp-caption   rs-parallaxlevel-4"
                        id="slide-3239-layer-2"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['44','57','39','0']"
                        data-fontsize="['17','17','17','15']"
                        data-lineheight="['26','26','26','24']"
                        data-width="['720','480','480','300']"
                        data-height="none"
                        data-whitespace="normal"
                        data-type="text"
                        data-responsive_offset="off"
                        data-responsive="off"
                        data-frames='[{"from":"y:10px;sX:0.9;sY:0.9;opacity:0;","speed":1000,"to":"o:1;","delay":700,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                        data-textalign="['center','center','center','center']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 6,
                          minWidth: "720px",
                          maxWidth: "720px",
                          rdxthemeSpace: "normal",
                          fontSize: "17px",
                          lineHeight: "26px",
                          fontWeight: 500,
                          color: "#fff",
                          borderWidth: "0px",
                        }}
                      >
                        With a strong sense of space planning, we build
                        exclusive and unique real estate projects catering to
                        the need of customers.{" "}
                      </div>
                      <div
                        className="tp-caption rev-btn  rs-parallaxlevel-4"
                        id="slide-3239-layer-4"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['133','159','141','102']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-type="button"
                        data-actions='[{"event":"click","action":"scrollbelow","offset":"px","delay":""}]'
                        data-responsive_offset="off"
                        data-responsive="off"
                        data-frames='[{"from":"y:20px;sX:0.9;sY:0.9;opacity:0;","speed":1000,"to":"o:1;","delay":900,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"0","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(238, 238, 238, 1.00);bs:solid;"}]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[30,30,30,30]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[30,30,30,30]"
                        style={{
                          zIndex: 7,
                          rdxthemeSpace: "nowrap",
                          fontSize: "15px",
                          lineHeight: "40px",
                          fontWeight: 700,
                          color: "rgba(0, 0, 0, 1.00)",
                          backgroundColor: "rgba(255, 255, 255, 1.00)",
                          borderColor: "rgba(0, 0, 0, 1.00)",
                          borderWidth: "0px",
                          outline: "none",
                          boxShadow: "none",
                          boxSizing: "border-box",
                          MozBoxSizing: "border-box",
                          WebkitBoxSizing: "border-box",
                          cursor: "pointer",
                        }}
                      >
                        READ MORE{" "}
                      </div>
                    </li>
                    <li
                      data-index="rs-3240 fw-bolder"
                      data-transition="fade"
                      data-slotamount="default"
                      data-hideafterloop={0}
                      data-hideslideonmobile="off"
                      data-easein="default"
                      data-easeout="default"
                      data-masterspeed="default"
                      data-thumb="images/main-slider/slide15.jpg"
                      data-rotate={0}
                      data-saveperformance="off"
                      data-title="Slide"
                      data-param1
                      data-param2
                      data-param3
                      data-param4
                      data-param5
                      data-param6
                      data-param7
                      data-param8
                      data-param9
                      data-param10
                      data-description
                    >
                      <video
                        src="images/bg2.mp4"
                        id="vid"
                        className="video"
                        poster="./images/background/bg12.jpg"
                        autoPlay
                        loop
                        muted
                      />
                      <div
                        className="tp-caption tp-shape tp-shapewrapper "
                        id="slide-100-layer-2"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['0','0','0','0']"
                        data-width="full"
                        data-height="full"
                        data-whitespace="nowrap"
                        data-type="shape"
                        data-basealign="slide"
                        data-responsive_offset="off"
                        data-responsive="off"
                        data-frames='[{"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},{"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 2,
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          borderColor: "rgba(0, 0, 0, 0)",
                          borderWidth: "0px",
                        }}
                      ></div>
                      <div
                        className="tp-caption   rs-parallaxlevel-4"
                        id="slide-3240-layer-1"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['-70','-70','-70','-100']"
                        data-fontsize="['60','60','40','20']"
                        data-lineheight="['70','70','50','30']"
                        data-width="['720','640','480','300']"
                        data-height="none"
                        data-whitespace="normal"
                        data-type="text"
                        data-responsive_offset="off"
                        data-responsive="off"
                        data-frames='[{"from":"y:20px;sX:0.9;sY:0.9;opacity:0;","speed":1000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                        data-textalign="['center','center','center','center']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 8,
                          minWidth: "720px",
                          maxWidth: "720px",
                          rdxthemeSpace: "normal",
                          fontSize: "60px",
                          lineHeight: "70px",
                          fontWeight: 900,
                          color: "#fff",
                          borderWidth: "0px",
                          letterSpacing: "10px",
                        }}
                      >
                        WE
                        <br />
                        <span style={{ color: "#9a2331" }}>CONSTRUCT</span>
                      </div>
                      <div
                        className="tp-caption   rs-parallaxlevel-4"
                        id="slide-3240-layer-2"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['44','57','39','0']"
                        data-fontsize="['17','17','17','15']"
                        data-lineheight="['26','26','26','24']"
                        data-width="['720','480','480','300']"
                        data-height="none"
                        data-whitespace="normal"
                        data-type="text"
                        data-responsive_offset="off"
                        data-responsive="off"
                        data-frames='[{"from":"y:10px;sX:0.9;sY:0.9;opacity:0;","speed":1000,"to":"o:1;","delay":700,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                        data-textalign="['center','center','center','center']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 9,
                          minWidth: "720px",
                          maxWidth: "720px",
                          rdxthemeSpace: "normal",
                          fontSize: "17px",
                          lineHeight: "26px",
                          fontWeight: 500,
                          color: "#fff",
                          borderWidth: "0px",
                        }}
                      >
                        With a strong team of construction professionals, we
                        construct all kinds of projects keeping in mind cost,
                        time and quality parameters.
                      </div>
                      <div
                        className="tp-caption ev-btn  rs-parallaxlevel-4"
                        id="slide-3240-layer-4"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['133','159','141','102']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-type="button"
                        data-actions='[{"event":"click","action":"scrollbelow","offset":"px","delay":""}]'
                        data-responsive_offset="off"
                        data-responsive="off"
                        data-frames='[{"from":"y:20px;sX:0.9;sY:0.9;opacity:0;","speed":1000,"to":"o:1;","delay":900,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"0","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(238, 238, 238, 1.00);bs:solid;"}]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[30,30,30,30]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[30,30,30,30]"
                        style={{
                          zIndex: 10,
                          rdxthemeSpace: "nowrap",
                          fontSize: "15px",
                          lineHeight: "40px",
                          fontWeight: 700,
                          color: "rgba(0, 0, 0, 1.00)",
                          backgroundColor: "rgba(255, 255, 255, 1.00)",
                          borderColor: "rgba(0, 0, 0, 1.00)",
                          borderWidth: "0px",
                          outline: "none",
                          boxShadow: "none",
                          boxSizing: "border-box",
                          MozBoxSizing: "border-box",
                          WebkitBoxSizing: "border-box",
                          cursor: "pointer",
                        }}
                      >
                        READ MORE{" "}
                      </div>
                    </li>
                    <li
                      data-index="rs-3241"
                      data-transition="fade"
                      data-slotamount="default"
                      data-hideafterloop="0"
                      data-hideslideonmobile="off"
                      data-easein="default"
                      data-easeout="default"
                      data-masterspeed="default"
                      data-thumb="images/main-slider/slide16.jpg"
                      data-rotate="0"
                      data-saveperformance="off"
                      data-title="Slide"
                      data-param1=""
                      data-param2=""
                      data-param3=""
                      data-param4=""
                      data-param5=""
                      data-param6=""
                      data-param7=""
                      data-param8=""
                      data-param9=""
                      data-param10=""
                      data-description=""
                      className="rs-3241"
                    >
                      <video
                        src="images/bg3.mp4"
                        id="vid"
                        className="video"
                        poster="./images/background/bg12.jpg"
                        autoPlay
                        loop
                        muted
                      />
                      <div
                        className="tp-caption tp-shape tp-shapewrapper"
                        id="slide-3241-layer-1"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['0','0','0','0']"
                        data-width="full"
                        data-height="full"
                        data-whitespace="nowrap"
                        data-type="shape"
                        data-basealign="slide"
                        data-responsive_offset="off"
                        data-responsive="off"
                        data-frames='[{"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},{"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                      ></div>
                      <div
                        className="tp-caption rs-parallaxlevel-4 fw-bolder text-white"
                        id="slide-3241-layer-2"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['-70','-70','-70','-100']"
                        data-fontsize="['60','60','40','20']"
                        data-lineheight="['70','70','50','30']"
                        data-width="['720','640','480','300']"
                        data-height="none"
                        data-whitespace="normal"
                        data-type="text"
                        data-responsive_offset="off"
                        data-responsive="off"
                        data-frames='[{"from":"y:20px;sX:0.9;sY:0.9;opacity:0;","speed":1000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                        data-textalign="['center','center','center','center']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                      >
                        WE
                        <br />
                        <span style={{ color: "#9a2331" }}>CONSULT</span>
                      </div>

                      <div
                        className="tp-caption rs-parallaxlevel-3 fw-medium text-white"
                        id="slide-3241-layer-3"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['44','57','39','0']"
                        data-fontsize="['17','17','17','15']"
                        data-lineheight="['26','26','26','24']"
                        data-width="['720','480','480','300']"
                        data-height="none"
                        data-whitespace="normal"
                        data-type="text"
                        data-responsive_offset="off"
                        data-responsive="off"
                        data-frames='[{"from":"y:10px;sX:0.9;sY:0.9;opacity:0;","speed":1000,"to":"o:1;","delay":700,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                        data-textalign="['center','center','center','center']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                      >
                        With a core team of highly qualified consultants, we
                        consult investors into making the right real estate
                        investments with high integrity guaranteeing optimized
                        returns.
                      </div>

                      <div
                        className="tp-caption rev-btn bg-white  rs-parallaxlevel-3"
                        id="slide-3241-layer-4"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['133','159','141','102']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-type="button"
                        data-actions='[{"event":"click","action":"scrollbelow","offset":"px","delay":""}]'
                        data-responsive_offset="off"
                        data-responsive="off"
                        data-frames='[{"from":"y:20px;sX:0.9;sY:0.9;opacity:0;","speed":1000,"to":"o:1;","delay":900,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"0","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(238, 238, 238, 1.00);bs:solid;"}]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[30,30,30,30]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[30,30,30,30]"
                      >
                        READ MORE{" "}
                      </div>
                    </li>
                  </ul>
                  <div
                    className="tp-bannertimer"
                    style={{
                      height: "8px",
                      backgroundColor: "rgba(255, 255, 255, 0.25)",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="section-full bg-white content-inner overlay-primary-dark text-white bg-img-fix"
          style={{
            backgroundImage:
              "linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url(images/background/bg18.jpg)",
          }}
        >
          <div className="container px-5">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                <div className="counter-left">
                  <div className="icon-lg pull-left m-tb5 mx-2">
                    <i className="fa fa-helmet-safety" />
                  </div>
                  <div className="clearfix m-l60">
                    <div className="m-b5">
                      <b className="counter">1.2</b>
                      <span>Mn Sq Ft</span>
                    </div>
                    <span className="font-16 font-monospace">DEVELOPED</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                <div className="counter-left">
                  <div className="icon-lg pull-left m-tb5 mx-2">
                    <i className="fa fa-person-digging" />
                  </div>
                  <div className="clearfix m-l60">
                    <div className="m-b5">
                      <b className="counter">1.5</b>
                      <span>Mn Sq Ft</span>
                    </div>
                    <span className="font-16 font-monospace">CONSTRUCTED</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                <div className="counter-left">
                  <div className="icon-lg pull-left m-tb5 mx-2 pb-4">
                    <i className="fa fa-hand-holding-dollar" />
                  </div>
                  <div className="clearfix m-l60">
                    <div className="m-b5" title="Indian Rupee">
                      <b className="counter">500</b>cr+
                    </div>
                    <span className="font-16 word-wrap font-monospace">
                      INVESTMENT CONSULTED
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 m-b10">
                <div className="counter-left">
                  <div className="icon-lg pull-left m-tb5 mx-2 pb-4">
                    <i className="fa fa-globe" />
                  </div>
                  <div className="clearfix m-l60">
                    <div className="m-b5">
                      <b className="counter">30</b>
                      <span>+</span>
                    </div>
                    <span className="font-16 font-monospace">
                      ASSOCIATED COMPANIES
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="section-full content-inner overlay-white-dark"
          style={{ backgroundImage: "url(images/background/bg18.jpg)" }}
        >
          <div className="container px-5">
            <div className="row">
              <div className="col-md-4 col-lg-4 col-sm-6 m-b30">
                <div className="dez-box fly-box">
                  <div className="dez-info visit-site p-a30 bg-white d-flex flex-column py-5">
                    <h2 className="dez-title m-t0 text-center">
                      <Link to="https://construction.aranathgroup.com/">
                        Construction Panel
                      </Link>
                    </h2>
                    <Link
                      to="https://construction.aranathgroup.com/"
                      className="site-button text-white"
                    >
                      Explore
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-6 m-b30">
                <div className="dez-box fly-box">
                  <div className="dez-info visit-site p-a30 bg-white d-flex flex-column py-5">
                    <h2 className="dez-title m-t0 text-center">
                      <Link to="https://development.aranathgroup.com/">
                        Architecture Marvels
                      </Link>
                    </h2>
                    <Link
                      to="https://development.aranathgroup.com/"
                      className="site-button text-white"
                    >
                      Explore
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-12 m-b10">
                <div className="dez-box fly-box">
                  <div className="dez-info visit-site p-a30 bg-white d-flex flex-column py-5">
                    <h2 className="dez-title m-t0 text-center">
                      <Link to="https://consulting.aranathgroup.com/">
                        Consulting Center
                      </Link>
                    </h2>
                    <Link
                      to="https://consulting.aranathgroup.com/"
                      className="site-button text-white"
                    >
                      Explore
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
